import React, { useState, useEffect } from "react";
import { Row, Col, Card, Table, Button, DatePicker } from "antd";
import "../styles/dashBoard.css";
import { useNavigate } from "react-router-dom";
import { Auth } from "aws-amplify";
import { api } from "../index";
import { connect } from "react-redux";
import { TextField, MenuItem } from "@mui/material";
import moment from "moment";
import CountUp from "react-countup";
import { checkAccess } from "./AuthorizationService";
import GenDashboardPDF from "./genDashboardPDF";
import { pdf } from "@react-pdf/renderer";
import { saveAs } from "file-saver";
import dayjs from "dayjs";
import { SpinnerCircular } from "spinners-react";
import { getItemsByTypeFunc } from "./functionCall";
import {
  getItemsByDatePagination,
  getTypeAndDateShopIdPagination,
  getItemsdatePagination,
} from "./functionCall";

const { RangePicker } = DatePicker;

function Dashboard(props) {
  const [allOutlets, setAllOutlets] = useState({});
  const [dates, setDates] = useState("Today");
  const [shopId, setShopId] = useState("");
  const [masterTotal, setMasterTotal] = useState(0);
  const [masterTotalCount, setMasterTotalCount] = useState(0);
  const [outletTotal, setOutletTotal] = useState(0);
  const [outletTotalCount, setOutletTotalCount] = useState(0);
  const [allLeads, setAllLeads] = useState([]);
  const [allQuotes, setAllQuotes] = useState([]);
  const [quoteTotal, setQuoteTotal] = useState(0);
  const [allProd, setAllProd] = useState([]);
  const [dateRange, setDateRange] = useState([
    dayjs(new Date()),
    dayjs(new Date()),
  ]);
  const [allSI, setAllSI] = useState([]);
  const [allPI, setAllPI] = useState([]);
  const [allExpense, setAllExpense] = useState([]);
  const [dashboard, setDashboard] = useState({});
  const [spinnerState, setSpinnerState] = useState(false);
  const navigate = useNavigate();

  const typeArray = ["Today", "Month", "Quarter", "Year"];

  useEffect(() => {
    const getUser = async () => {
      await Auth.currentAuthenticatedUser({
        bypassCache: false,
      })
        .then(async (user) => {
          setShopId(user.attributes["custom:shopId"]);
          getTypeDetails(user.attributes["custom:shopId"]);
          await getAllOutlets(user.attributes["custom:shopId"]);
          console.log("Use effect 1");
        })
        .catch((err) => {
          console.log("err " + JSON.stringify(err));
          if (err == "The user is not authenticated") navigate("/");
        });
    };
    getUser();
    if (!checkAccess("Dashboard")) navigate("/homePage");
  }, []);

  const columns = [
    {
      title: "Id",
      dataIndex: "randomId",
      key: "randomId",
      width: "15%",
    },
    {
      title: "Location",
      dataIndex: "location",
      key: "location",
      width: "15%",
    },
    {
      title: "Order Count",
      dataIndex: "orderCount",
      key: "orderCount",
      width: "15%",
      sorter: (a, b) => a.orderCount - b.orderCount,
    },
    {
      title: "Total Order",
      dataIndex: "totalOrder",
      key: "totalOrder",
      render: (totalOrder) => parseFloat(totalOrder).toFixed(2),
      width: "15%",
      align: "right",
      sorter: (a, b) => a.totalOrder - b.totalOrder,
    },
    // {
    //   title: "Quotation Count",
    //   dataIndex: "quotationCount",
    //   key: "quotationCount",
    //   width: "15%",
    //   sorter: (a, b) => a.quotationCount - b.quotationCount,
    // },
    // {
    //   title: "Total Quotation",
    //   dataIndex: "totalQuotation",
    //   key: "totalQuotation",
    //   render: (totalQuotation) => parseFloat(totalQuotation).toFixed(2),
    //   width: "15%",
    //   align: "right",
    //   sorter: (a, b) => a.totalQuotation - b.totalQuotation,
    // },
    // {
    //   title: "Quotation in Pipeline",
    //   dataIndex: "pipeline",
    //   key: "pipeline",
    //   width: "15%",
    // },
    // {
    //   title: "Quotation Convert to Order",
    //   dataIndex: "convertedToOrder",
    //   key: "convertedToOrder",
    //   width: "15%",
    // },
    // {
    //   title: "Quotation Rejected",
    //   dataIndex: "rejected",
    //   key: "rejected",
    //   width: "15%",
    // },
  ];

  const columnsQuot = [
    {
      title: "New Quotation",
      dataIndex: "id",
      key: "id",
      render: () => allQuotes.filter((item) => item.status === "live").length,
    },
    {
      title: "Scheduled Visit",
      dataIndex: "id",
      key: "id",
      render: () =>
        allQuotes.filter((item) => item.status === "Follow Up").length,
    },
    {
      title: "Convert to Order",
      dataIndex: "id",
      key: "id",
      render: () =>
        allQuotes.filter((item) => item.status === "Convert to Order").length,
    },
    {
      title: "Rejected",
      dataIndex: "id",
      key: "id",
      render: () =>
        allQuotes.filter((item) => item.status === "Rejected").length,
    },
  ];

  const columnsLeads = [
    {
      title: "New Lead",
      dataIndex: "id",
      key: "id",
      render: () => allLeads.filter((item) => item.status === "live").length,
    },
    {
      title: "Follow Up",
      dataIndex: "id",
      key: "id",
      render: () =>
        allLeads.filter((item) => item.status === "Follow Up").length,
    },
    {
      title: "Convert to Quote",
      dataIndex: "id",
      key: "id",
      render: () =>
        allLeads.filter((item) => item.status === "Convert to Quote").length,
    },
    {
      title: "Cancelled",
      dataIndex: "id",
      key: "id",
      render: () =>
        allLeads.filter((item) => item.status === "Cancelled").length,
    },
  ];

  const columnsProd = [
    {
      title: "New",
      dataIndex: "id",
      key: "id",
      render: () => allProd.filter((item) => item.status === "live").length,
    },
    {
      title: "Cutting Complete",
      dataIndex: "id",
      key: "id",
      render: () =>
        allProd.filter((item) => item.status === "Cutting Complete").length,
    },
    {
      title: "Mesh Process",
      dataIndex: "id",
      key: "id",
      render: () =>
        allProd.filter((item) => item.status === "Mesh Process").length,
    },
    {
      title: "Packed",
      dataIndex: "id",
      key: "id",
      render: () => allProd.filter((item) => item.status === "Packed").length,
    },
    {
      title: "Dispatched",
      dataIndex: "id",
      key: "id",
      render: () =>
        allProd.filter((item) => item.status === "Dispatched").length,
    },
    {
      title: "Delivered",
      dataIndex: "id",
      key: "id",
      render: () =>
        allProd.filter((item) => item.status === "Delivered").length,
    },
    {
      title: "Installed",
      dataIndex: "id",
      key: "id",
      render: () =>
        allProd.filter((item) => item.status === "Installed").length,
    },
  ];

  const getAllOutlets = async (shopId) => {
    let temp = {};

    let allItems = await getItemsByTypeFunc("Outlet", shopId);
    allItems.map((item) => {
      temp[item.id] = {};
      temp[item.id].id = item.id;
      temp[item.id].randomId = item.randomId;
      temp[item.id].location = item.location;
      temp[item.id].outletType = item.outletType;
    });

    setAllOutlets(temp);
    await getDashboardDetails(temp, shopId);
  };

  const getDashboardDetails = async (allOutlets, shopId) => {
    setOutletTotal(0);
    setOutletTotalCount(0);
    setMasterTotal(0);
    setMasterTotalCount(0);
    setDashboard({});
    let outletTotal = 0;
    let outletCount = 0;
    console.log("getDashboardDetails");
    setSpinnerState(true);
    let masterDetails = await fetchAPIDetails(shopId);
    setMasterTotal(masterDetails.dashboard.totalOrder);
    setMasterTotalCount(masterDetails.dashboard.orderCount);
    for (const value of Object.keys(allOutlets)) {
      let outletDetails = await fetchAPIDetails(value);
      let newKeyValues = {
        id: value,
        randomId: allOutlets[value].randomId,
        location: allOutlets[value].location,
        outletType: allOutlets[value].outletType,
        totalOrder: outletDetails.dashboard.totalOrder,
        orderCount: outletDetails.dashboard.orderCount,
        // totalQuotation: outletDetails.dashboard.totalQuotation,
        // quotationCount: outletDetails.dashboard.quotationCount,
        // convertedToOrder: outletDetails.dashboard.convertedToOrder,
        // pipeline: outletDetails.dashboard.pipeline,
        // rejected: outletDetails.dashboard.rejected,
      };

      outletTotal = outletTotal + outletDetails.dashboard.totalOrder;
      outletCount = outletCount + outletDetails.dashboard.orderCount;

      setDashboard((prevObject) => {
        const newKey = value;
        return { ...prevObject, [newKey]: newKeyValues };
      });
    }
    setOutletTotal(outletTotal);
    setOutletTotalCount(outletCount);
    setSpinnerState(false);
  };

  const getTypeDetails = async (shopId) => {
    let allLd = await getTypeAndDateShopIdPagination(
      "Lead",
      shopId,
      dateRange[0].format("YYYY-MM-DD"),
      dateRange[1].format("YYYY-MM-DD")
    );
    setAllLeads(allLd);

    let allQt = await getTypeAndDateShopIdPagination(
      "Quotation",
      shopId,
      dateRange[0].format("YYYY-MM-DD"),
      dateRange[1].format("YYYY-MM-DD")
    );
    let total = 0;
    allQt.map((item) => {
      total += item.totalAmount;
    });

    setAllQuotes(allQt);
    setQuoteTotal(total);

    let allProd = await getItemsByDatePagination(
      "Production",
      shopId,
      dateRange[0].format("YYYY-MM-DD"),
      dateRange[1].format("YYYY-MM-DD")
    );

    setAllProd(allProd);

    let allSI = await getItemsdatePagination(
      "SalesInvoice",
      shopId,
      dateRange[0].format("YYYY-MM-DD"),
      dateRange[1].format("YYYY-MM-DD")
    );
    setAllSI(allSI);

    let allPI = await getItemsdatePagination(
      "PurchaseInvoice",
      shopId,
      dateRange[0].format("YYYY-MM-DD"),
      dateRange[1].format("YYYY-MM-DD")
    );
    setAllPI(allPI);

    let allExpense = await getItemsdatePagination(
      "Expense",
      shopId,
      dateRange[0].format("YYYY-MM-DD"),
      dateRange[1].format("YYYY-MM-DD")
    );
    setAllExpense(allExpense);
  };

  const fetchAPIDetails = async (outletShopId) => {
    let res = {};
    try {
      const response = await fetch(api + "dashboard", {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify({
          outletShopId: outletShopId,
          startDate: dateRange[0].format("YYYY-MM-DD"),
          endDate: dateRange[1].format("YYYY-MM-DD"),
        }),
      });
      const responseJson = await response.json();
      if (responseJson) res = responseJson;
    } catch (error) {
      console.error(error);
    }
    return res;
  };

  useEffect(() => {
    const getDashboard = async () => {
      console.log("Use effect 2");
      getTypeDetails(shopId);
      await getDashboardDetails(allOutlets, shopId);
    };
    if (shopId != "" && Object.keys(allOutlets).length > 0) getDashboard();
  }, [dateRange]);

  useEffect(() => {
    if (dates != "") {
      let startDate = "";
      let endDate = moment(new Date()).format("YYYY-MM-DD");
      if (dates == "Today") startDate = endDate;
      else if (dates == "Month")
        startDate = moment().startOf("month").format("YYYY-MM-DD");
      else if (dates == "Quarter")
        startDate = moment().startOf("quarter").format("YYYY-MM-DD");
      else startDate = moment().startOf("year").format("YYYY-MM-DD");

      let temp = [
        dayjs(moment(startDate).toDate()),
        dayjs(moment(endDate).toDate()),
      ];
      setDateRange(temp);
    }
  }, [dates]);

  const downloadPDF = async () => {
    let tempDashboard = dashboard;
    const sortedKeys = Object.keys(tempDashboard).sort((a, b) => {
      const aTotal = tempDashboard[a]?.totalOrder || 0;
      const bTotal = tempDashboard[b]?.totalOrder || 0;
      return bTotal - aTotal;
    });
    const sortedObject = {};
    sortedKeys.forEach((key) => {
      sortedObject[key] = tempDashboard[key];
    });

    await pdf(
      <GenDashboardPDF allOutlets={sortedObject} dateRange={dateRange} />
    )
      .toBlob()
      .then(async (blob) => {
        saveAs(
          blob,
          `Dashboard-${dateRange[0].format("DD-MM-YY")}-${dateRange[1].format(
            "DD-MM-YY"
          )}.pdf`
        );
      });
  };

  return (
    <div className="Dashboard">
      <div className="dashboard-container">
        <h3 className="card-heading">Application Statistics</h3>
        {spinnerState && <SpinnerCircular />}

        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            alignContent: "center",
            paddingLeft: 10,
            paddingRight: 10,
          }}
        >
          <TextField
            select
            margin="normal"
            variant="outlined"
            className="date-select"
            value={dates}
            size="small"
            onChange={(e) => {
              setDates(e.target.value);
            }}
            autoComplete="off"
            style={{ backgroundColor: "white" }}
          >
            {typeArray.map((item) => (
              <MenuItem key={item} value={item}>
                {item == "Today" ? "" : "This"} {item}
              </MenuItem>
            ))}
          </TextField>
          <RangePicker
            style={{ width: "20%" }}
            placeholder="Select Date Range"
            onChange={(date) => setDateRange(date)}
            value={dateRange}
          />
          <Button style={{ paddingRight: 25 }} onClick={() => downloadPDF()}>
            Download PDF
          </Button>
        </div>
        <Row gutter={[16, 16]} justify="center">
          <Col xs={10} sm={8} md={6} lg={4}>
            <Card className="dashboard-card" hoverable>
              <div className="card-content">
                <h1 className="static-count">
                  <CountUp start={0} end={outletTotalCount} duration={2} />
                </h1>
                <h2 className="static-subheading">Sales Orders</h2>
              </div>
            </Card>
          </Col>
          <Col xs={10} sm={8} md={6} lg={4}>
            <Card className="dashboard-card" hoverable>
              <div className="card-content">
                <h1 className="static-count">
                  <CountUp start={0} end={masterTotalCount} duration={2} />
                </h1>
                <h2 className="static-subheading">Work Orders</h2>
              </div>
            </Card>
          </Col>
          <Col xs={10} sm={8} md={6} lg={4}>
            <Card className="dashboard-card" hoverable>
              <div className="card-content">
                <h1 className="static-count">
                  <CountUp start={0} end={allQuotes.length} duration={2} />
                </h1>
                <h2 className="static-subheading">Quotation</h2>
              </div>
            </Card>
          </Col>
          <Col xs={10} sm={8} md={6} lg={4}>
            <Card className="dashboard-card" hoverable>
              <div className="card-content">
                <h1 className="static-count">
                  <CountUp start={0} end={allSI.length} duration={2} />
                </h1>
                <h2 className="static-subheading">SI</h2>
              </div>
            </Card>
          </Col>
          <Col xs={10} sm={8} md={6} lg={4}>
            <Card className="dashboard-card" hoverable>
              <div className="card-content">
                <h1 className="static-count">
                  <CountUp start={0} end={allPI.length} duration={2} />
                </h1>
                <h2 className="static-subheading">PI</h2>
              </div>
            </Card>
          </Col>
          <Col xs={10} sm={8} md={6} lg={4}>
            <Card className="dashboard-card" hoverable>
              <div className="card-content">
                <h1 className="static-count">
                  <CountUp start={0} end={allExpense.length} duration={2} />
                </h1>
                <h2 className="static-subheading">Expense</h2>
              </div>
            </Card>
          </Col>
        </Row>
        <Row gutter={[16, 16]} justify="center">
          <Col xs={10} sm={8} md={6} lg={4}>
            <Card className="dashboard-card" hoverable>
              <div className="card-content">
                <h1 className="static-count">
                  {props.common.symbol}{" "}
                  <CountUp start={0} end={outletTotal} duration={2} />
                </h1>
                <h2 className="static-subheading">SO Value</h2>
              </div>
            </Card>
          </Col>
          <Col xs={10} sm={8} md={6} lg={4}>
            <Card className="dashboard-card" hoverable>
              <div className="card-content">
                <h1 className="static-count">
                  {props.common.symbol}{" "}
                  <CountUp start={0} end={masterTotal} duration={2} />
                </h1>
                <h2 className="static-subheading">WO Value</h2>
              </div>
            </Card>
          </Col>
          <Col xs={10} sm={8} md={6} lg={4}>
            <Card className="dashboard-card" hoverable>
              <div className="card-content">
                <h1 className="static-count">
                  {props.common.symbol}{" "}
                  <CountUp start={0} end={quoteTotal} duration={2} />
                </h1>
                <h2 className="static-subheading">Quote Value</h2>
              </div>
            </Card>
          </Col>
          <Col xs={10} sm={8} md={6} lg={4}>
            <Card className="dashboard-card" hoverable>
              <div className="card-content">
                <h1 className="static-count">
                  {props.common.symbol}{" "}
                  <CountUp
                    start={0}
                    end={allSI
                      .reduce((sum, bill) => sum + bill.totalAmount, 0)
                      .toFixed(2)}
                    duration={2}
                  />
                </h1>
                <h2 className="static-subheading">SI Value</h2>
              </div>
            </Card>
          </Col>
          <Col xs={10} sm={8} md={6} lg={4}>
            <Card className="dashboard-card" hoverable>
              <div className="card-content">
                <h1 className="static-count">
                  {props.common.symbol}{" "}
                  <CountUp
                    start={0}
                    end={allPI
                      .reduce((sum, bill) => sum + bill.totalAmount, 0)
                      .toFixed(2)}
                    duration={2}
                  />
                </h1>
                <h2 className="static-subheading">PI Value</h2>
              </div>
            </Card>
          </Col>
          <Col xs={10} sm={8} md={6} lg={4}>
            <Card className="dashboard-card" hoverable>
              <div className="card-content">
                <h1 className="static-count">
                  {props.common.symbol}{" "}
                  <CountUp
                    start={0}
                    end={allExpense
                      .reduce((sum, bill) => sum + bill.totalAmount, 0)
                      .toFixed(2)}
                    duration={2}
                  />
                </h1>
                <h2 className="static-subheading">Expense Value</h2>
              </div>
            </Card>
          </Col>
        </Row>
        <div className="dividerDashBoard"></div>

        <Row gutter={[16, 16]}>
          <Col xs={24} md={12}>
            <Card>
              <Table
                dataSource={[
                  {
                    key: "1",
                    name: "Mike",
                    age: 32,
                    address: "10 Downing Street",
                  },
                ]}
                columns={columnsLeads}
                className="my-table"
                pagination={false}
              />
            </Card>
          </Col>
          <Col xs={12} md={6}>
            <Card className="dashboard-card" hoverable>
              <div className="card-content">
                <h1 className="static-count">
                  <CountUp
                    start={0}
                    end={Object.keys(allOutlets).length}
                    duration={2}
                  />
                </h1>
                <h2 className="static-subheading">Outlets</h2>
              </div>
            </Card>
          </Col>
          <Col xs={12} md={6}>
            <Card className="dashboard-card" hoverable>
              <div className="card-content">
                <h1 className="static-count">
                  <CountUp start={0} end={allLeads.length} duration={2} />
                </h1>
                <h2 className="static-subheading">Lead Count</h2>
              </div>
            </Card>
          </Col>
        </Row>
        <div style={{ marginTop: 20 }}></div>
        <Row gutter={[16, 16]}>
          <Col xs={24} md={12}>
            <Card>
              <Table
                dataSource={[
                  {
                    key: "1",
                    name: "Mike",
                    age: 32,
                    address: "10 Downing Street",
                  },
                ]}
                columns={columnsQuot}
                className="my-table"
                pagination={false}
              />
            </Card>
          </Col>
          <Col xs={24} md={12}>
            <Card>
              <Table
                dataSource={[
                  {
                    key: "1",
                    name: "Mike",
                    age: 32,
                    address: "10 Downing Street",
                  },
                ]}
                columns={columnsProd}
                className="my-table"
                pagination={false}
              />
            </Card>
          </Col>
        </Row>

        <div style={{ marginTop: 20 }}>
          <h3 className="card-heading">Outlet Sales</h3>
          <Table
            dataSource={Object.values(dashboard)}
            columns={columns}
            className="my-table"
            pagination={false}
            scroll={{ y: 520 }}
          />
        </div>
      </div>
    </div>
  );
}

const mapStateToProps = (state) => {
  return {
    common: state.common,
  };
};

function mapDispatchToProps(dispatch) {
  return {};
}
export default connect(mapStateToProps, mapDispatchToProps)(Dashboard);
